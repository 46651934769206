import { gql } from '@apollo/client/core'

/**
 * Gets a user's account.
 */
export const GET_COLLECTION = gql`
  query GetCollection($id: ID!) {
    getCollection(id: $id) {
      id
      contractId
      status
    }
  }
`
