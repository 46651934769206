import { ImageOptions } from './types'

const getExtensionFromBlob = (blob: Blob): string => {
  // Split the string by '/' to separate the type and subtype
  const parts = blob.type.split('/')

  // The file extension is typically the subtype
  const fileExtension = parts.length > 1 ? parts[1] : 'jpg'

  return fileExtension
}

export default function useImage () {
  const serverUrl = import.meta.env.VITE_IMAGE_HANDLER_URL as string
  const bucketName = import.meta.env.VITE_ASSET_BUCKET as string

  const  toBinaryStr = (str) => {
    const encoder = new TextEncoder()
    // This splits the UTF-16 string into an array of bytes
    const charCodes = encoder.encode(str)
    // This concatenates the byte data to create a binary string
    return String.fromCharCode(...charCodes)
  }

  const getImage = (key: string, edits: ImageOptions = {}): string => {
    const body = btoa(toBinaryStr(JSON.stringify({
      bucket: bucketName,
      key,
      edits,
    })))

    return `${serverUrl}/${body}`
  }

  const getVideo = (key: string): string => {
    return `https://${bucketName}.s3.amazonaws.com/${key}`
  }

  return {
    getImage,
    getVideo,
    getExtensionFromBlob,
  }
}
